<template>
	<div class="contain flex">
		<div class="page-content">
			<div class="bm-wapper">
				<div class="br-bar">
					<step-bar :active="0"></step-bar>
				</div>
				<div class="bm-content">
					<title-bar :titles="['招考报名','打印准考证']"></title-bar>
					<div class="zk-list-item" v-for="item in newslist" :key="item.id">
						<div class="zk-center">
							{{ item.examName }}
						</div>
						<div class="zk-left">
							<el-button class="btn" @click="gozkInfoPage(item.id)">打印准考证</el-button>
						</div>
					</div>
					<el-empty v-if="newslist.length < 1" :image-size="200"></el-empty>
					<div style="text-align: center; margin-top: 20px">
						<el-pagination hide-on-single-page @size-change="handleSizeChange"
							@current-change="handleCurrentChange" :current-page="selfrom.pageNumber"
							:page-sizes="[20, 50, 100, 150, 200]" :page-size="selfrom.pageSize"
							layout="total, sizes, prev, pager, next, jumper" :total="total">
						</el-pagination>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import StepBar from './stepbar.vue';
	import TitleBar from './titlebar.vue';
	import utils from "@/assets/js/utils";
	import {
		isnull
	} from "@/assets/js/index";
	import {
		ExamPageList,
		ExamSignUpList
	} from "@/api/user";
	export default {
		components: {
			StepBar,
			TitleBar
		},
		data() {
			return {
				active: 0,
				selfrom: {
					pageNumber: 1,
					pageSize: 20,
					KM: "",
					cid: ''
				},
				total: 0,
				newslist: [],
			}
		},
		created() {
			if (!isnull(localStorage.getItem("userinfo"))) {
				this.user = JSON.parse(localStorage.getItem("userinfo"));
				if (this.user.peR_ID == null) {
					this.resolvewin("/login", {
						type: 1
					}, 1);
				}
			}
			this.getPage()
		},
		methods: {
			gozkInfoPage(id) {
				this.$router.push("Ticket?id=" + id)
			},
			getPage() {
				var _this = this;
				this.selfrom.types = 6;
				this.selfrom.cid = this.user.peR_ID;
				ExamSignUpList(this.selfrom).then((res) => {
					if (res.success) {
						_this.newslist = res.data.rows;
						_this.total = res.data.total;
					}
				});
			},
			handleSizeChange(val) {
				this.selfrom.pageNumber = 1;
				this.selfrom.pageSize = val;
				this.getPage();
			},
			handleCurrentChange(val) {
				this.selfrom.pageNumber = val;
				this.getPage();
			},
			search() {
				this.selfrom.pageNumber = 1;
				this.getPage();
			},
		}
	}
</script>
<style lang="scss" scoped>
	.contain {
		background-color: #fff;
		min-height: calc(100vh - 125px);
	}

	.page-content {
		width: 1200px;
		margin: 0 auto;
		padding: 20px 0;
	}


	.bm-wapper {
		padding: 20px 0;
		display: flex;
	}

	.bm-bar {
		width: 220px;
		display: flex;
	}

	.bm-content {
		flex: 1;
		padding: 0 48px;
	}

	.zk-list-item {
		margin-top: 20px;
		border: 1px solid #f8f8f8;
		padding: 12px;
		cursor: pointer;
		display: flex;
		line-height: 60px;

		.zk-left {
			flex: 1;
			font-size: 14px;
			color: #333333;

			.btn {
				background-color: #098FF0;
				width: 80px;
				height: 30px;
				line-height: 30px;
				color: #fff;
				padding: 0;
			}
		}

		.zk-center {
			flex: 6;
			font-size: 16px;
			padding: 0 10px;
		}

		.zk-right {
			font-size: 13px;
			color: #898989;
			width: 60px;
			height: 60px;
			background-color: #098FF0;
			text-align: center;
			color: #fff;
			display: block;

			h1 {
				line-height: 40px;
				display: block;
				padding: 0;
				margin: 0;
			}

			span {
				line-height: 20px;
				display: block;
			}
		}
	}

	.zk-pagination {
		text-align: right;
		padding: 30px 0;
		padding-bottom: 0px;
	}
</style>